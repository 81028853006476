import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { pushParams } from 'dpl/actions/query_params';
import {
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEW_FEATURE_LOCATIONS
} from 'dpl/constants/analytics';
import {
  ANALYTICS_VIEW,
  INITIAL_INQUIRY_SOURCE,
  LITTER_ID,
  ONE_CLICK_APPLY_MODAL_OPEN,
  PUPPY_ID
} from 'dpl/constants/query_params';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { buyerApplicationResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import { get, removeEmptyValues } from 'dpl/shared/utils/object';
import { getShortNumericDateAndYear } from 'dpl/shared/utils/date';
import {
  BUYER_MESSAGES_PATH,
  BUYER_APPLICATIONS_PATH
} from 'dpl/shared/constants/urls';
import LoadingDots from 'dpl/components/LoadingDots';

const BUTTON_VARIANTS = {
  base: 'w-100 flex items-center justify-center ph3 pv3 br3 ba',
  primary: 'fw-medium white b--purple bg-purple',
  secondary: 'fw-normal b--moon-gray bg-light-gray',
  loading: 'bg-white b--light-purple'
};

export default function ModalRequestMoreInfoButton({
  analytics,
  breederProfileId,
  litterId,
  puppyId,
  inquirySource,
  variants
}) {
  const dispatch = useDispatch();

  const { value, isFetching } = useResourceQuery(
    buyerApplicationResourceDefinition(breederProfileId)
  );

  const computedAnalytics = {
    ...analytics,
    view_feature: ANALYTICS_VIEW_FEATURES.REQUEST_INFO_BUTTON,
    view_feature_location: ANALYTICS_VIEW_FEATURE_LOCATIONS.CTA
  };

  const currentUser = useCurrentUser();

  const initialInquiries = get(
    currentUser,
    'value.data.breeder_profiles_initial_inquiries',
    []
  );

  const initialInquiry = initialInquiries.find(
    inquiry => inquiry.breeder_profile_id === breederProfileId
  );

  const buyerAppSubmittedDate = value?.buyer_application?.submitted_at;

  const alreadyApplied = Boolean(buyerAppSubmittedDate || initialInquiry);

  const appliedToDate = getShortNumericDateAndYear(
    new Date(buyerAppSubmittedDate || initialInquiry?.created_at)
  );

  function getButtonCTA() {
    let buttonCta = 'Request more info';

    if (alreadyApplied) {
      buttonCta = buyerAppSubmittedDate
        ? `Applied to breeder ${appliedToDate}`
        : `You requested info on ${appliedToDate}`;
    }

    return buttonCta;
  }

  function handleClick() {
    if (isFetching) return;

    if (alreadyApplied) {
      window.location.href = buyerAppSubmittedDate
        ? BUYER_APPLICATIONS_PATH
        : `${BUYER_MESSAGES_PATH}/active/${initialInquiry?.conversation_id}`;
    } else {
      dispatch(
        pushParams(
          removeEmptyValues({
            [ONE_CLICK_APPLY_MODAL_OPEN]: breederProfileId,
            [PUPPY_ID]: puppyId,
            [LITTER_ID]: litterId,
            [ANALYTICS_VIEW]: computedAnalytics.view,
            [INITIAL_INQUIRY_SOURCE]: inquirySource
          })
        )
      );
    }
  }

  function getButtonClassNames() {
    const buttonVariants = { ...BUTTON_VARIANTS, ...variants };

    return classnames(buttonVariants.base, 'transition', {
      [buttonVariants.loading]: isFetching,
      [buttonVariants.primary]: !isFetching && !alreadyApplied,
      [buttonVariants.secondary]: !isFetching && alreadyApplied
    });
  }

  return (
    <button
      className={getButtonClassNames()}
      onClick={handleClick}
      type="button"
      disabled={isFetching}
    >
      {isFetching ? <LoadingDots height="1rem" isVisible /> : getButtonCTA()}
    </button>
  );
}

ModalRequestMoreInfoButton.propTypes = {
  analytics: PropTypes.shape({
    breeder_preferred_status: PropTypes.string,
    view: PropTypes.string
  }),
  breederProfileId: PropTypes.number.isRequired,
  litterId: PropTypes.number,
  puppyId: PropTypes.number,
  inquirySource: PropTypes.string,
  variants: PropTypes.shape({
    base: PropTypes.string,
    primary: PropTypes.string,
    secondary: PropTypes.string
  })
};

ModalRequestMoreInfoButton.defaultProps = {
  litterId: null,
  puppyId: null,
  inquirySource: null,
  analytics: {},
  variants: {
    base: BUTTON_VARIANTS.base,
    primary: BUTTON_VARIANTS.primary,
    secondary: BUTTON_VARIANTS.secondary,
    loading: BUTTON_VARIANTS.loading
  }
};
